<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-header text-center bg-primary">
        <h3>Create New Single AX</h3>
      </div>
      <div class="card-body">
        <h5>
          <router-link
            :to="{
              name: AxItemPath.name,
            }"
          >
            <span class="badge badge-primary"
              ><i class="fa fa-rotate-left mr-1"></i>Back</span
            >
          </router-link>
        </h5>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="organization"
                >Item Name<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="itemId"
                label="name"
                track-by="name"
                placeholder="type to find item..."
                open-direction="bottom"
                :options="items"
                :searchable="true"
                :loading="isLoadingItemSearch"
                :close-on-select="true"
                @search-change="findItemName"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
              <small class="font-weight-bold">ITEM ID : {{ this.itemid_label }}</small>
            </div>

            <div class="form-group">
              <label for="organization"
                >Name<span class="text-danger">*</span> :</label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="EBOOK:Filosofi Teras"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Base Price<span class="text-danger">*</span> :</label
              >
              <input
                v-model="basePrice"
                type="number"
                class="form-control"
                placeholder="49000"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Single ID AX<span class="text-danger">*</span> :</label
              >
              <input
                v-model="single_idax"
                type="number"
                class="form-control"
                placeholder="528196376"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Vendor<span class="text-danger">*</span> :</label
              >
              <multiselect
                v-model="vendor"
                label="name"
                track-by="name"
                placeholder="type to find vendor..."
                open-direction="bottom"
                :options="items_vendor"
                :searchable="true"
                :loading="isLoadingSearch"
                :close-on-select="true"
                @search-change="findVendorName"
                @input="getVendorAx"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <div class="form-group">
              <label>Vendor ID AX : </label>
              <input
                v-model="vendor_id_ax"
                type="text"
                class="form-control"
                placeholder="V-000005276"
              />
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="addSingleAx()"
            >
              <i class="fa fa-plus"></i>
              Create Single AX
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { AxItemPath } from '../../router/content';

export default {
  name: 'CreateSingleAX',
  data() {
    return {
      AxItemPath,
      itemid_label: null,
      itemId: [],
      items: [],
      isLoadingItemSearch: false,
      name: null,
      basePrice: null,
      single_idax: null,
      vendor: [],
      items_vendor: [],
      isLoadingSearch: false,
      vendor_id_ax: null,
    };
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/ax/items');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },

    itemId: function() {
      if (!this.itemId) return;
      this.name = this.itemId?.name
      this.itemid_label = this.itemId?.id
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Create Single AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ItemSoax.isLoading,
      isError: (state) => state.ItemSoax.isError,
      successMessage: (state) => state.ItemSoax.successMessage,
      errorMessage: (state) => state.ItemSoax.errorMessage,
      itemsEbook: (state) => state.ebooks.items,
    }),
  },
  methods: {
    ...mapActions('ItemSoax', ['createSingleAx']),
    ...mapActions('vendors', ['findVendor', 'fetchVendorsById']),
    ...mapActions('ebooks', ['searchBooks']),

    addSingleAx() {
      const payload = {
        item_id: this.itemId?.id,
        name: this.name,
        base_price: this.basePrice,
        single_idax: this.single_idax,
        vendor: this.vendor ? this.vendor?.name : null,
        publisher: this.vendor ? this.vendor?.name : null,
        vendor_id_ax: this.vendor_id_ax,
      };
      this.createSingleAx(payload);
    },

    findItemName(keyword) {
      if (keyword.length > 2) {
        this.isLoadingItemSearch = true;
        let payload = {
          q: keyword,
        };
        this.searchBooks(payload)
          .then(() => {
            this.items = this.itemsEbook
            this.isLoadingItemSearch = false;
          })
          .catch((err) => {
            this.isLoadingItemSearch = false;
            console.log(err);
          });
      }
    },

    findVendorName(keyword) {
      if (keyword.length > 2) {
        this.isLoadingSearch = true;
        let payload = {
          q: keyword,
        };
        this.findVendor(payload)
          .then((resp) => {
            this.items_vendor = resp.data.data;
            this.isLoadingSearch = false;
          })
          .catch((err) => {
            this.isLoadingSearch = false;
            console.log(err);
          });
      }
    },

    getVendorAx() {
      this.fetchVendorsById(this.vendor).then((res) => {
        this.vendor_id_ax = res.data?.data?.sync_vendor?.vendor_id_ax;
      });
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>